/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Text, TitleMain, ContactForm, Title } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rezervace"}>
        <SiteHeader />

        <Column className="--left pb--20 pt--50" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--20 pr--20 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--3 fs--18 w--300 swpf--uppercase ls--04 lh--2" href={"/de/"} content={"Zurück"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right fs--18 w--300 text-box--invert swpf--uppercase ls--04 lh--2 pb--08 pt--08" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary);\">Café Romanca</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--10" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--154 w--300 ls--01 lh--12" style={{"maxWidth":""}} content={"Buchung"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"dbg7kfk0buc"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 ff--2 fs--22 w--300" style={{"maxWidth":1118}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Reservieren","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--30 pl--20 pr--20 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--62 swpf--uppercase" content={"<span style=\"color: var(--color-dominant);\">Café Romanca</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 text-box--invert ls--04 lh--2 mt--30" style={{"maxWidth":570}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--0 pl--0" innerClassName="pb--02 pl--0" content={"<span style=\"color: var(--color-dominant);\">Nach oben</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/fotogallerie"} content={"<span style=\"color: var(--color-dominant);\">Fotogalerie</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/menu"} content={"<span style=\"color: var(--color-dominant);\">Menü</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/buchung"} content={"<span style=\"color: var(--color-dominant);\">Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}